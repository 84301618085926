<template>
  <div class="flex_column productStatistics">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
      :query="query"
    ></dog-search>
    <div class="flex_1 productStatistics__graph">
      <echarts
        ref="productLine"
        :config="productLine"
        v-loading="isLoading"
      ></echarts>
    </div>
  </div>
</template>

<script>
  import searchDate from '@/components/searchDate';
  import companyService from '@/api/service/company';
  import echarts from '@/components/echarts';
  import { setLocalStorageItem } from '@/utils/methods/common';
  import statisticsService from '@/api/service/statistics';
  import { timeFormat } from '@/utils/methods/common';

  export default {
    name: 'productStatistics',
    components: {
      echarts
    },
    data() {
      return {
        query: {},
        searchConfig: [
          {
            type: 'select',
            label: '所属公司',
            key: 'companyId',
            attrs: {
              service: this.getCompanyList,
              clearable: false,
              'select-first': true
            }
          },
          {
            key: 'dateValue',
            type: 'custom',
            label: '日期',
            component: searchDate,
            attrs: {
              default: [
                timeFormat(new Date(), 'YYYY-MM-DD'),
                timeFormat(
                  new Date().getTime() + 1000 * 60 * 60 * 24,
                  'YYYY-MM-DD'
                )
              ]
            }
            // size: 'long'
          },
          {
            type: 'select',
            label: '数量',
            key: 'countLimit',
            attrs: {
              options: [
                { label: '10个', value: 10 },
                { label: '20个', value: 20 },
                { label: '30个', value: 30 },
                { label: '50个', value: 50 },
                { label: '100个', value: 100 }
              ],
              clearable: false,
              default: 10
            }
          }
        ],
        productLine: require('./productLine'),
        isLoading: false
      };
    },
    mounted() {
      this.$refs.productLine.echarts.getZr().off('click'); // 先取消点击，再触发，免得造成连续抖动触发
      this.$refs.productLine.echarts.getZr().on('click', (params) => {
        let pointInPixel = [params.offsetX, params.offsetY];
        if (this.$refs.productLine.echarts.containPixel('grid', pointInPixel)) {
          let pointInGrid = this.$refs.productLine.echarts.convertFromPixel(
            {
              seriesIndex: 0
            },
            pointInPixel
          );
          let xIndex = pointInGrid[0]; // 索引
          let op = this.$refs.productLine.echarts.getOption();
          //获得图表中点击的列
          let dataY = op.dataset[0].source[xIndex]; //获取点击的列名
          setLocalStorageItem('productQuery', dataY.productId);
          this.$router.push({
            path: '/operationManage/productManage'
          });
        }
      });
    },
    methods: {
      getCompanyList() {
        return companyService.getOwnCompanyEnum().then((res) => {
          if (!res[0]) {
            this.$message.error('请先维护公司信息！');
            return res;
          }
          this.query.companyId = res[0].id;
          this.getData();
          return res;
        });
      },
      getData() {
        this.$refs.search.search();
      },
      search(query) {
        this.isLoading = true;
        statisticsService
          .getProductStatistics({
            ...query,
            dateStart: query.dateValue[0],
            dateEnd: query.dateValue[1],
            dateValue: null
          })
          .then((res) => {
            this.totalData = res.total;
            this.$refs.productLine.echarts.setOption({
              dataset: {
                source: res.list
              }
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(productStatistics) {
    @include e(graph) {
      position: relative;
    }
    @include e(tag) {
      position: absolute;
      top: 30px;
      right: 30px;
      display: flex;
      font-size: 13px;
      @include e(item) {
        & + & {
          margin-left: 20px;
        }
        & > div {
          &:first-child {
            display: flex;
            align-items: center;
            font-weight: bold;
            .svg-icon {
              position: relative;
              top: -3px;
              width: 20px;
              height: 20px;
              margin-left: 5px;
              vertical-align: top;
            }
          }
          &:last-child {
            color: #717780;
          }
        }
      }
    }
  }
</style>
